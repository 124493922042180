import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ProgressiveImage from "react-progressive-graceful-image";

import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from '../../../common/Footer';
import AvailableOnMobile from '../../../common/available-on-mobile';

import PosBanner from '../../../assets/img/pos-banner.png';
import StorePayments from '../../../assets/img/store-payments.png';
import StorePaymentsMobile from '../../../assets/img/store-payments-mobile.png';
import PosDeviceMobile from '../../../assets/img/pos-device-mobile.png';
import StoreWiseSummary from '../../../assets/img/store-wise-summary.png';
import posDdeviceNew from '../../../assets/img/pos-device-new.png';
import addMemberAccess from '../../../assets/img/add-member-access.png';
import addMemberAccessMobile from '../../../assets/img/add-member-access-mobile.png';

import PosBannerLazy from '../../../assets/img/webp/pos-banner.webp';
import StorePaymentsLazy from '../../../assets/img/webp/store-payments.webp';
import StorePaymentsMobileLazy from '../../../assets/img/webp/store-payments-mobile.webp';
import PosDeviceMobileLazy from '../../../assets/img/webp/pos-device-mobile.webp';
import StoreWiseSummaryLazy from '../../../assets/img/webp/store-wise-summary.webp';
import posDdeviceNewLazy from '../../../assets/img/webp/pos-device-new.webp';
import addMemberAccessLazy from '../../../assets/img/webp/add-member-access.webp';
import addMemberAccessMobileLazy from '../../../assets/img/webp/add-member-access-mobile.webp';


import { Helmet } from "react-helmet";

const Pos = () => {
  useEffect(() => {
    document.title = "Open Current Account Online for Business | Tijoree"
    let descMeta = document.querySelector('meta[name="description"]')
    descMeta.setAttribute("content", 'Open a business current account online with Tijoree, the leading neobank for businesses. Enjoy the convenience of hassle-free account opening, seamless digital transactions, and tailored banking solutions. Empower your business with our innovative tools and exceptional customer service. Start your banking journey with Tijoree today and experience the future of business banking.');
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    AOS.init({
      easing: 'ease-out-back',
      duration: 700,
      once: true,
      // mirror: true,
      disable: 'mobile',
    });
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title> POS | Tijoree
        </title>
        <meta name="description" content=" Open a business current account online with Tijoree, the leading neobank for businesses. Enjoy the convenience of hassle-free account opening, seamless digital transactions, and tailored banking solutions. Empower your business with our innovative tools and exceptional customer service. Start your banking journey with Tijoree today and experience the future of business banking." /></Helmet>
      <div className="page-wrapper">
        <section id="inner-top-section" className="inner-top-section grey-bg-2 pos-banner">
          <div className="container-md">
            <div className="inner-content">
              <div className="row">
                <div className="col-md-9">
                  <div className="page-title">POS</div>
                  <div className="row">
                    <div className="col-md-5">
                      <h2 className="size34">Collect In-Store UPI<br></br> & Card Payments</h2>
                    </div>
                    <div className="col-md-5">
                      <p className='size20'>Lowest transaction fees with Tijoree's POS machine</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="action-btns">
                <Link to={'/form'} className="button btn-yellow">Get Started</Link>
              </div>
            </div>
            <div className="overview-img text-center">
              <picture>
                <source type="image/webp" srcSet={PosBannerLazy}></source>
                <source type="image/png" srcSet={PosBanner}></source>
                <img className="img" alt="POS" src={PosBanner} />
              </picture>
            </div>
          </div>
        </section>

        <div className='pos-bluebg'>

          <section id="pos-devicenew" className="pos-devicenew">
            <div className="container-md">
              <div className='main-box'>
                <div className="row hover-box">
                  <div className="col-md-5 mobile-order-2">
                    <div className='store-payment-img'>

                      <picture>
                        <source type="image/webp" srcSet={posDdeviceNewLazy}></source>
                        <source type="image/png" srcSet={posDdeviceNew}></source>
                        <img className="desktop hover-img" alt="Store Payments" src={posDdeviceNew} />
                      </picture>
                      <picture>
                        <source type="image/webp" srcSet={PosDeviceMobileLazy}></source>
                        <source type="image/png" srcSet={PosDeviceMobile}></source>
                        <img className="mobile hover-img" alt="Store Payments" src={PosDeviceMobile} />
                      </picture>

                    </div>
                  </div>
                  <div className="col-md-7 mobile-order-1 div-text">
                    <div className='instore'>
                      <h3 className='size34'>Your business can now accept all types of payments</h3>
                      <Link className="button btn-purple" to={'/form'}>Enquire Now</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="get-paid" className="get-paid pos store-payment">
            <div className="container-md">
              <div className="main-box hover-box">
                <div className="inner-content">
                  <div className="row">

                    <div className="col-md-6 mobile-order-1">
                      <div className='instore'>
                        <h3 className='size40'>Keep track of all in-store payments</h3>
                      </div>
                    </div>
                    <div className="col-md-6 mobile-order-2">
                      <div className='store-payment-img'>

                        <picture>
                          <source type="image/webp" srcSet={StorePaymentsLazy}></source>
                          <source type="image/png" srcSet={StorePayments}></source>
                          <img className="desktop hover-img" alt="In-Store" src={StorePayments} />
                        </picture>
                        <picture>
                          <source type="image/webp" srcSet={StorePaymentsMobileLazy}></source>
                          <source type="image/png" srcSet={StorePaymentsMobile}></source>
                          <img className="mobile hover-img" alt="In-Store" src={StorePaymentsMobile} />
                        </picture>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section id="store-summarynew" className="store-summarynew">
            <div className="container-md">
              <div className="row hover-out">
                <div className="col-md-6 mobile-order-1">
                  <div className='store-payment-img'>
                    <picture>
                      <source type="image/webp" srcSet={StoreWiseSummaryLazy}></source>
                      <source type="image/png" srcSet={StoreWiseSummary}></source>
                      <img className="hover-img" alt="Get a summary" src={StoreWiseSummary} />
                    </picture>
                  </div>
                </div>
                <div className="col-md-6  mobile-order-2">
                  <div className='instore'>
                    <h3 className='size40'>Get a summary of collections from all your stores in one place</h3>
                  </div>
                </div>

              </div>
            </div>
          </section>

          <section id="store-summarynew" className="store-summarynew addmember">
            <div className="container-md">
              <div className="row align-items-center hover-out">

                <div className="col-md-6 mobile-order-2">
                  <div className='instore'>
                    <h3 className='size40'>Manage member access to your dashboard</h3>
                  </div>
                </div>

                <div className="col-md-6 mobile-order-1">
                  <div className='store-payment-img'>

                    <picture>
                      <source type="image/webp" srcSet={addMemberAccessLazy}></source>
                      <source type="image/png" srcSet={addMemberAccess}></source>
                      <img className="desktop hover-img" alt="Add Member & Edit Access" src={addMemberAccess} />
                    </picture>
                    <picture>
                      <source type="image/webp" srcSet={addMemberAccessMobileLazy}></source>
                      <source type="image/png" srcSet={addMemberAccessMobile}></source>
                      <img className="mobile hover-img" alt="Add Member & Edit Access" src={addMemberAccessMobile} />
                    </picture>

                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>



        {/*
        <section id="send-customize-reminder" className="send-customize-reminder pos-devices-main">
          <div className="container-md">
            <div className="row">
              <div className="col-md-6 pos-device-left">
                <div className="send-reminder hover-box">
                  <div className="row">
                    <div className="col-md-12 d-flex justify-content-start align-items-end minh230">
                      <img className="desktop hover-img" src={StoreWiseSummary} alt="Store Wise Summary" />
                      <img className="mobile hover-img" src={StoreWiseSummaryMobile} alt="Store Wise Summary" />
                    </div>
                  </div>
                </div>
                <div className="bottom-content">
                  <div className="row">
                    <div className="col-md-5">
                      <h5>See a Store Wise Summary<br></br> of your collection</h5>
                    </div>
                    <div className="col-md-7">
                      <p>Porttitor enim eget suspendisse ullamcorper.</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 pos-device-right">
                <div className="customize-reminder hover-box">
                  <div className="row">
                    <div className="col-md-6 d-flex justify-content-center align-items-end minh230">
                      <img className="desktop hover-img" src={PosDevice} alt="POS Device" />
                      <img className="mobile hover-img" src={PosDeviceMobile} alt="POS Device" />
                    </div>
                    <div className="col-md-6 pl-0">
                      <div className="content-left">
                        <h4>Get a POS Device to Collect Payment in your Store</h4>
                        <Link className="button btn-white medium" to={'/form'}>Enquire Now</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bottom-content">
                  <div className="row">
                    <div className="col-md-4 col-sm-12">
                      <h5>Add Member & Edit Access</h5>
                    </div>
                    <div className="col-md-8 col-sm-12">
                      <p>Porttitor enim eget suspendisse ullamcorper. Montes risus lacinia tellus consectetur at eleifend sapien.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="get-paid" className="get-paid manage-access">
          <div className="container-md">
            <div className="main-box hover-box">
              <div className="inner-content">
                <div className="row">
                  <div className="col-md-5">
                    <div className='manage-access-img'>
                      <img className="desktop hover-img" src={ManageAccess} alt="Manage Access" />
                      <img className="mobile hover-img" src={ManageAccessMobile} alt="Manage Access" />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <h5>Admin Access</h5>
                    <p>View UPI and Card transactions & settlement for all stores</p>
                    <p>View Disputed Transactions</p>
                    <p>Add/Remove Store Managers</p>
                    <p>Add/Remove Admin</p>
                    <p>Download XLSX of all transactions & settlements</p>
                  </div>
                  <div className="col-md-3">
                    <h5>Store Manager Access</h5>
                    <p>View UPI & Swipe transactions for the assigned store</p>
                    <p>Download XLSX for that assigned store’s transactions</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-md">
            <div className="bottom-content">
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <h5>Add Member & Edit Access</h5>
                </div>
                <div className="col-md-5 col-sm-12">
                  <p>Porttitor enim eget suspendisse ullamcorper. Montes risus lacinia tellus consectetur at eleifend sapien.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
  */}
        {/*
        <section id="faq-section" className="faq-section">
          <div className="container-md">
            <div className="faq-head">
              <h1>FAQ</h1>
              <p>Frequently Asked Questions</p>
            </div>
            <div class="accordion" id="accountOpening">
              <div class="accordion-item">
                <h4 class="accordion-header" id="title1"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="false" aria-controls="collapseOne" data-bs-parent="#accountOpening">What is the account opening process?</button></h4>
                <div id="collapse1" class="accordion-collapse collapse" aria-labelledby="title1" data-bs-parent="#accountOpening">
                  <div class="accordion-body">
                    <p>Account opening of current account will be as per the existing process. Existing Account Opening Form (AOF) needs to be used for account opening. KYC &amp; related documents as applicable basis entity type.</p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h4 class="accordion-header" id="title2"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse2" aria-expanded="false" aria-controls="collapseTwo" data-bs-parent="#accountOpening">What is a One Person Company Account?</button></h4>
                <div id="collapse2" class="accordion-collapse collapse" aria-labelledby="title2" data-bs-parent="#accountOpening">
                  <div class="accordion-body">
                    <p>The concept of OPC allows a single person to run a company limited by shares, and Sole proprietorship means an entity where it is run and owned by one individual and where there is no distinction between the owner and the business</p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h4 class="accordion-header" id="title3"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse3" aria-expanded="false" aria-controls="collapseThree" data-bs-parent="#accountOpening">Is AADHAAR Mandatory for Authorized Signatories?</button></h4>
                <div id="collapse3" class="accordion-collapse collapse" aria-labelledby="title3" data-bs-parent="#accountOpening">
                  <div class="accordion-body">
                    <p>AADHAAR is Mandatory for all Authorized Signatories</p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h4 class="accordion-header" id="title4"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapseThree" data-bs-parent="#accountOpening">I do not have a physical Pan Card, but I have a pan card number.?</button></h4>
                <div id="collapse4" class="accordion-collapse collapse" aria-labelledby="title4" data-bs-parent="#accountOpening">
                  <div class="accordion-body">
                    <p>If a PAN card is not available, a printout of the PAN for the account holder may be taken from <a href="http://incometaxindia.gov.in/" target="_blank">http://incometaxindia.gov.in/</a> - the printout must be stamped “Downloaded/Verified at Branch” by Bank officials.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
       */}
        <section id="cta-bg-curve" class="cta-bg-curve inner text-center">
          <div className="container-md">
            <h4>Get POS & Collect In-store Payments</h4>
            <Link className="button btn-purple" to={'/form'}>Enquire Now</Link>
          </div>
        </section>

        <section id="available-versions" className="available-versions">
          <div className="container-md">
            <AvailableOnMobile></AvailableOnMobile>
          </div>
        </section>

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Pos;
