import React, { useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import OwlCarousel from 'react-owl-carousel';
import ProgressiveImage from "react-progressive-graceful-image";
// import Marquee from "react-fast-marquee";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Footer from '../../common/Footer';
import AvailableOnMobile from '../../common/available-on-mobile';
import $ from 'jquery';
import AOS from 'aos';
import 'aos/dist/aos.css';

// Images imported from assets
import heroTopCard from '../../assets/img/hero-top-card.png';
import heroRightCard from '../../assets/img/hero-right-card.png';
import growBusiness from '../../assets/img/grow-business.png';
import overviewFrame from '../../assets/img/overview.png';
import directions from '../../assets/img/directions.svg';
import creditCard from '../../assets/img/credit-card.png';
import cardCorner from '../../assets/img/card-corner.png';
import vendorPayments from '../../assets/img/vendor-payments.png';
import vendorPaymentsMobile from '../../assets/img/vendor-payments-mobile.png';

import heroTopCardLazy from '../../assets/img/webp/hero-top-card.webp';
import heroRightCardLazy from '../../assets/img/webp/hero-right-card.webp';
import overviewFrameLazy from '../../assets/img/webp/overview.webp';
import creditCardLazy from '../../assets/img/webp/credit-card.webp';
import cardCornerLazy from '../../assets/img/webp/card-corner.webp';
import vendorPaymentsLazy from '../../assets/img/webp/vendor-payments.webp';
import vendorPaymentsMobileLazy from '../../assets/img/webp/vendor-payments-mobile.webp';



import { Helmet } from "react-helmet";



const Landing = () => {
  const virtual = useRef(null);
  document.title = "Tijoree | Neobank Offering Digital Banking Services"
  let descMeta = document.querySelector('meta[name="description"]')
  descMeta.setAttribute("content", 'Experience the future of banking with Tijoree, the leading neobank offering digital banking services designed exclusively for SMEs and startups. Simplify your financial operations, streamline transactions, and unlock growth opportunities with our innovative solutions. Contact us today and embark on a seamless banking journey tailored to your business needs.');
  const owlRef = useRef(null);
  let path = useLocation();


  const navigate = useNavigate()
  useEffect(() => {
    if (path?.state?.id) {
      const elements = document.getElementById(path?.state?.id);
      setTimeout(() => {
        elements.scrollIntoView({ behavior: "smooth", block: "end" });
      }, 100)
      navigate({ ...path, state: null });
    }
  }, [path?.state?.id])


  const owlResponsiveOption = {
    0: {
      items: 1,
    },
    676: {
      items: 2,
    },
    768: {
      items: 2,
    },
    992: {
      items: 3,
    },
    1200: {
      items: 4,
    },
    1600: {
      items: 4,
    },
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    AOS.init({
      easing: 'ease-out-back',
      duration: 900,
      disable: 'mobile',
    });

  }, []);

  const handleCarouselchanged = (e) => {
    // console.log('eeeeeeee',e);
    if (!e.namespace) {
      return;
    }
    const carousel = e.relatedTarget;
    $('#owl-slider-counter .current').text(
      carousel.relative(carousel.current() + 1)
    );

    $('#owl-slider-counter .total').text('/' + carousel.items().length);
  };

  const handleClickScroll = () => {
    const element = document.getElementById('frameVideo');
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleOwlNav = (type) => {
    if (type === 'NEXT') {
      owlRef.current.next(500);
    } else if (type === 'PREV') {
      owlRef.current.prev(500);
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title> Tijoree: Digital Banking Services
        </title>
        <meta name="description" content=" Experience the future of banking with Tijoree, the leading neobank offering digital banking services designed exclusively for SMEs and startups. Simplify your financial operations, streamline transactions, and unlock growth opportunities with our innovative solutions. Contact us today and embark on a seamless banking journey tailored to your business needs." /></Helmet>
      <div className="page-wrapper">

        <section id="home-main-section">
          <picture>
            <source type="image/webp" srcSet={heroTopCardLazy}></source>
            <source type="image/png" srcSet={heroTopCard}></source>
            <img className="topcard" alt="SUPER-CHARGE YOUR BUSINESS FINANCES" src={heroTopCard} />
          </picture>
          <picture>
            <source type="image/webp" srcSet={heroRightCardLazy}></source>
            <source type="image/png" srcSet={heroRightCard}></source>
            <img className="rightcard" alt="SUPER-CHARGE YOUR BUSINESS FINANCES" src={heroRightCard} />
          </picture>
          <div className="container-md">
            <div className="row justify-content-lg-center">
              <div className="col-lg-12 herotext text-center">
                <h1>SUPER-CHARGE YOUR BUSINESS FINANCES WITH TIJOREE</h1>
                <p>Fuel your business's growth with our seamless financial solutions. Elevate your operations effortlessly with our tailored financial account services.</p>
                <div className="btns-group">
                  <Link className="button btn-yellow" to="/form">Get Started</Link>
                </div>
              </div>
              <div className="col-lg-12 text-center nofee">
                <ul>
                  <li>No Subscription Fee</li>
                  <li><span className='d-lg-block d-md-none d-sm-none'>₹0 Non Maintenance Charges</span> <span className='d-lg-none'>₹0 NMC</span></li>
                  <li>₹0 Transaction Fees</li>
                </ul>
              </div>
              {/*
              <div className="col-lg-12 text-center poweredBy">
                <p>Powered By</p>
                <img src={growBusiness} alt="Powered By" />                
              </div>
             */}
            </div>

          </div>
        </section>
        {/* 
        <section id="cards">
          <div className="container-md">
            <div className="cards">
              <div className="row">
                <div className="col-md-4">
                  <div className="card card-1 hover-box">
                    <img className="hover-img"
                      src={earnInterestImg}
                      alt="Earn Interest"
                    />
                  </div>
                  <p>*Earn up to 7% interest on your AMB</p>
                </div>
                <div className="col-md-4">
                  <div className="card card-2 hover-box">
                    <img width="120" className="hover-img"
                      src={securityCertified}
                      alt="Security Certified"
                    />
                    <p>Ac id condimentum ipsum fermentum praesent. Dui neque mi condimentum pretium sagittis. Risus nunc tempor aliquam.</p>
                  </div>
                  <p>Security Certified</p>
                </div>
                <div className="col-md-4">
                  <div className="card card-3 hover-box">
                    <p>Ac id condimentum ipsum fermentum praesent. Dui neque mi condimentum pretium sagittis. Risus nunc tempor aliquam.</p>
                    <img className="coupon-img hover-img"
                      src={couponCard}
                      alt="Coupon"
                    />
                    <div className="giftbox hover-img">
                      <img
                        src={gift}
                        alt="gift"
                      />
                    </div>
                  </div>
                  <p>Exclusive Rewards</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        */}

        {/* 
          <section id="know-about-pos" className="know-about-pos">
          <div className="container-md">
            <div className="row">
              <div className="col-md-4">
                <div className="card card-1">
                  <h2>Low</h2>
                  <h4>MDR Charges</h4>
                  <p>Massa blandit ultricies bibendum accumsan vitae quam scelerisque id.</p>
                </div>
                <div className='poslink'><Link className="about-pos-link" to={'/'}>Know About POS</Link></div>

              </div>
              <div className="col-md-4">
                <div className="card card-2">
                  <h2>No</h2>
                  <h4>Subscription Fee</h4>
                  <p>Massa blandit ultricies bibendum accumsan vitae quam scelerisque id.</p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card card-3">
                  <h2><span className="currency">₹</span>0 <span className="sub">NMC</span></h2>
                  <p>Massa blandit ultricies bibendum accumsan vitae quam scelerisque id.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      */}
        <section id="get-paid" className="get-paid homegetpaid">
          <div className="container-md">
            <div className="row">
              <div className="col-md-12 text-center">
                <div className="directions">
                  <img
                    src={directions}
                    alt="Pay & Get Paid"
                  />
                </div>
                <h2>Simplified Banking</h2>
                <p className="head-sub">Current Account</p>
              </div>
            </div>
            <div className="gradient-card hover-box">
              <div className="inner-content">
                <picture>
                  <source type="image/webp" srcSet={overviewFrameLazy}></source>
                  <source type="image/png" srcSet={overviewFrame}></source>
                  <img className="overview-frame desktop" alt="Simplified Banking" src={overviewFrame} />
                </picture>
                <div className="columns-box">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className='size40'>Manage all your Business Finances in one place</h3>
                      <p className='size20'>Seamlessly link multiple bank accounts and experience the convenience of transferring funds from a single unified platform</p>
                      <div className='action'>
                        <Link className="button btn-white oca" to="/form">Open a Current Account</Link>
                        <Link className="button btn-white op25" to="/products/current-account">Know More</Link>
                      </div>
                    </div>

                    <div className="col-md-4 text-center">
                      <picture>
                        <source type="image/webp" srcSet={overviewFrameLazy}></source>
                        <source type="image/png" srcSet={overviewFrame}></source>
                        <img className="overview-frame mobile" alt="Simplified Banking" src={overviewFrame} />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        {/*
        <section id="make-bulk-payment" className="bulk-payment">
          <div className="container-md">
            <div className="row">
              <div className="col-md-6">
                <div className="grey-pattern-full hover-box p-0">
                  <img className="hover-img desktop"
                    src={transactionSuccess}
                    alt="Make Payments in Bulk"
                  />
                  <img className="hover-img mobile"
                    src={transactionSuccessMobile}
                    alt="Make Payments in Bulk"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <h4>Make Payments in Bulk</h4>
                <p>Porttitor enim eget suspendisse ullamcorper. Montes risus lacinia tellus consectetur at eleifend sapien. Morbi fermentum enim ut ultrices sapien diam feugiat.</p>
                <Link className="button btn-purple medium" to="/">Know More</Link>
              </div>
            </div>
          </div>
        </section>
      */}
        {/*
        <section id="payment-reminder-steps" className="payment-reminder-steps">
          <div className="container-md">
            <div className="outer-box grey-pattern-full">
              <div className="inner">
                <ul>
                  <li>
                    <div className="hover-box"><img className="hover-img"
                      src={solarServer}
                      alt="Solar server"
                    /></div>
                    <p class="d-md-none">Update Debtor’s List</p>
                  </li>
                  <li>
                    <div className="hover-box">
                      <img className="hover-img"
                        src={solarFile}
                        alt="Solar file"
                      />
                    </div>
                    <p class="d-md-none">Upload Invoice</p>
                  </li>
                  <li>
                    <div className="hover-box">
                      <img className="hover-img"
                        src={solarCalendar}
                        alt="Solar Calendar"
                      />
                    </div>
                    <p class="d-md-none">Send Reminder</p>
                  </li>
                  <li>
                    <div className="hover-box">
                      <img className="hover-img"
                        src={solarRupee}
                        alt="Solar Calendar"
                      />
                    </div>
                    <p class="d-md-none">Get Paid</p>
                  </li>
                </ul>
                <div className="row bottom-content">
                  <div className="col-md-4">
                    <h4>Send Payment Reminders to your Customers</h4>
                  </div>
                  <div className="col-md-5">
                    <p>Porttitor enim eget suspendisse ullamcorper. Montes risus lacinia tellus consectetur at eleifend sapien.
                      Morbi fermentum enim ut ultrices sapien diam feugiat.</p>
                  </div>
                  <div className="col-md-3">
                    <Link className="button btn-white medium" to="/">Try Now</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        */}



        <section id="business-cc" className="business-cc">
          <div className="container-md">
            <div className="main-head text-center">
              <div className="head-icon cc-icon">
              </div>
              <h2>Empowering businesses, Streamlining expenses</h2>
              <p className="head-sub">Corporate Credit Card</p>
            </div>
            <div className="purple-gradient-box hover-box">
              <div className="inner-box">
                <div className="content-box">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 className='size40'>Manage your Business Expenses with our Corporate Credit Card</h3>
                      <p className='size20'>Enjoy credit for up to 52 days, and make strategic investments as a result of this extended credit facility</p>
                      <Link className="button btn-white" to="/form">Get your Credit Card</Link>
                    </div>
                    <div className="col-md-6">
                      <picture>
                        <source type="image/webp" srcSet={creditCardLazy}></source>
                        <source type="image/png" srcSet={creditCard}></source>
                        <img className="credit hover-img" alt="Business with our Credit Card" src={creditCard} />
                      </picture>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="virtual-cards" className="virtual-cards">
          <div className="container-md">
            <div className="card-group">
              <div className="row">
                <div className="col-md-7">
                  <h3 className='size40'>Create Virtual Cards</h3>
                  <p className='size20'>Simplify online payments with Virtual Cards. Create secure, one-time-use digital cards for safer online transactions. Shop online worry-free, knowing your actual card details remain protected.</p>
                  <p className='d-sm-block d-lg-none d-md-none'><Link className="button btn-white" to="/form">Know More</Link></p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="monitor-spending" className="monitor-spending">
          <div className="container-md">
            <div className="grey-pattern-full hover-box">
              <div className="content-box">
                <div className="row">
                  <div className="col-md-6">
                    <div className='vendor-payments-text'>
                      <h3 className='size40'>Vendor Payments</h3>
                      <p className='size20'>Directly pay your vendor's bank accounts from your corporate card swiftly and securely</p>
                    </div>
                  </div>
                  <div className="col-md-6 vendorimg">
                    <picture>
                        <source type="image/webp" srcSet={vendorPaymentsLazy}></source>
                        <source type="image/png" srcSet={vendorPayments}></source>
                        <img className="desktop card-img hover-img" alt="Vendor Payments" src={vendorPayments} />
                      </picture>
                      <picture>
                        <source type="image/webp" srcSet={vendorPaymentsMobileLazy}></source>
                        <source type="image/png" srcSet={vendorPaymentsMobile}></source>
                        <img className="mobile card-img hover-img" alt="Vendor Payments" src={vendorPaymentsMobile} />
                      </picture>                    
                  </div>
                  <div className="cardcorner">
                    <span className="overflow-hidden">
                      <picture>
                        <source type="image/webp" srcSet={cardCornerLazy}></source>
                        <source type="image/png" srcSet={cardCorner}></source>
                        <img className="card-img hover-img" alt="Vendor Payment" src={cardCorner} />
                      </picture>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* 
        <section id="trusted-partners" className="trusted-partners purple-gradient-box">
          <div className="container-md">
            <div className="inner-box">
              <div className="row justify-content-lg-center">
                <div className="col-lg-6 col-md-13 col-sm-12 text-center">
                  <h2 className='size40'>Our Trusted Partners</h2>
                  <p className='size20'>Powering your financial journey with the backing of esteemed partners: YES Bank, Unity Bank. Your business is in trusted hands every step of the way</p>
                  <div className="partners">
                    <img
                      src={partners}
                      alt="partners"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        */}

        {/* 
        <section id="testemonials" className="prevent-select">
          <div className="slide-main">
            <div className="container-md">
              <div className="title text-center">
                <img
                  src={testemonialIcon}
                  alt="Reviews"
                />
                <h4>Here’s what out customers say</h4>
              </div>
              <OwlCarousel
                className="owl-theme"
                items={3}
                margin={25}
                dots={true}
                nav={true}
                onChanged={(e) => handleCarouselchanged(e)}
                ref={owlRef}
                responsiveClass={true}
                responsive={owlResponsiveOption}
              >
                <div className="slide first">
                  <div className="slide-info">
                    <div className="slider-img">
                      <img
                        src={sliderImg}
                        alt="slider img"
                      />
                    </div>
                    <div className="author">Nandan Raikwar (Owner)</div>
                    <div className="designation">Radha & Sons Kitchenware</div>
                    <div className="content">Massa blandit ultricies bibendum accumsan vitae quam scelerisque id. Quis odio nisl dolor in sit faucibus mollis tristique.</div>
                  </div>
                </div>
                <div className="slide">
                  <div className="slide-info">
                    <div className="slider-img">
                      <img
                        src={sliderImg}
                        alt="slider img"
                      />
                    </div>
                    <div className="author">Nandan Raikwar (Owner)</div>
                    <div className="designation">Radha & Sons Kitchenware</div>
                    <div className="content">Massa blandit ultricies bibendum accumsan vitae quam scelerisque id. Quis odio nisl dolor in sit faucibus mollis tristique.</div>
                  </div>
                </div>
                <div className="slide">
                  <div className="slide-info">
                    <div className="slider-img">
                      <img
                        src={sliderImg}
                        alt="slider img"
                      />
                    </div>
                    <div className="author">Nandan Raikwar (Owner)</div>
                    <div className="designation">Radha & Sons Kitchenware</div>
                    <div className="content">Massa blandit ultricies bibendum accumsan vitae quam scelerisque id. Quis odio nisl dolor in sit faucibus mollis tristique.</div>
                  </div>
                </div>
                <div className="slide">
                  <div className="slide-info">
                    <div className="slider-img">
                      <img
                        src={sliderImg}
                        alt="slider img"
                      />
                    </div>
                    <div className="author">Nandan Raikwar (Owner)</div>
                    <div className="designation">Radha & Sons Kitchenware</div>
                    <div className="content">Massa blandit ultricies bibendum accumsan vitae quam scelerisque id. Quis odio nisl dolor in sit faucibus mollis tristique.</div>
                  </div>
                </div>
                <div className="slide">
                  <div className="slide-info">
                    <div className="slider-img">
                      <img
                        src={sliderImg}
                        alt="slider img"
                      />
                    </div>
                    <div className="author">Nandan Raikwar (Owner)</div>
                    <div className="designation">Radha & Sons Kitchenware</div>
                    <div className="content">Massa blandit ultricies bibendum accumsan vitae quam scelerisque id. Quis odio nisl dolor in sit faucibus mollis tristique.</div>
                  </div>
                </div>
                <div className="slide">
                  <div className="slide-info">
                    <div className="slider-img">
                      <img
                        src={sliderImg}
                        alt="slider img"
                      />
                    </div>
                    <div className="author">Nandan Raikwar (Owner)</div>
                    <div className="designation">Radha & Sons Kitchenware</div>
                    <div className="content">Massa blandit ultricies bibendum accumsan vitae quam scelerisque id. Quis odio nisl dolor in sit faucibus mollis tristique.</div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </section>
        */}

        <section id="available-on-mobile">
          <div className="container-md">
            <AvailableOnMobile></AvailableOnMobile>
          </div>
        </section>

        {/* 
        <section id="gradient-text-card" className="gradient-text-card text-center">
          <div className="container-md">
            <div className="row">
              <div className="col-md-4">
                <div className="value">71<span>%</span></div>
                <p>Improvement in efficiency for customers who switched to Tijoree Products</p>
              </div>
              <div className="col-md-4">
                <div className="value">5k+</div>
                <p>Businesses are currently using Tijoree Products</p>
              </div>
              <div className="col-md-4">
                <div className="value"><span>₹</span>12k</div>
                <p>Average annual savings for customers who switched to Tijoree's Current Account</p>
              </div>
            </div>
          </div>
        </section>
        */}

        <Footer />
      </div>
    </React.Fragment>
  );
};

export default Landing;
