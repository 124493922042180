import React from 'react';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ProgressiveImage from "react-progressive-graceful-image";

import AvailableOnMobileImg from '../assets/img/available-on-mobile.png';
import apple from '../assets/img/apple.svg';
import playstore from '../assets/img/playstore.svg';
import AvailableOnMobileImg1 from '../assets/img/available-on-mobile-1.png';
import AvailableOnMobileImg2 from '../assets/img/available-on-mobile-2.png';

import AvailableOnMobileImgLazy from '../assets/img/webp/available-on-mobile.webp';
import AvailableOnMobileImg1Lazy from '../assets/img/webp/available-on-mobile-1.webp';
import AvailableOnMobileImg2Lazy from '../assets/img/webp/available-on-mobile-2.webp';



const AvailableOnMobile = () => {
  return (
    <div className="available-mobile hover-box">
      <div className="row">
        <div className="col-md-6 text">
          <picture>
            <source type="image/webp" srcSet={AvailableOnMobileImg1Lazy}></source>
            <source type="image/png" srcSet={AvailableOnMobileImg1}></source>
            <img className="hover-img mobile" alt="Available OnMobile" src={AvailableOnMobileImg1} />
          </picture>

          <h3>Download our Mobile App & manage your business finances from anywhere.</h3>
          <div className="device-list">
            <ul>
              <li><a href='https://apps.apple.com/in/app/tijoree/id1600055538' target='_blank'>
              <img className="hover-img" src={apple} alt="Apple" />                
              </a></li>
              <li><a href='https://play.google.com/store/apps/details?id=money.tijoree' target='_blank'>                
                <img className="hover-img" src={playstore} alt="Playstore" /> 
              </a></li>
            </ul>
          </div>
        </div>
        <div className="col-md-6 frame">
          <picture>
            <source type="image/webp" srcSet={AvailableOnMobileImgLazy}></source>
            <source type="image/png" srcSet={AvailableOnMobileImg}></source>
            <img className="hover-img desktop" alt="Available OnMobile" src={AvailableOnMobileImg} />
          </picture>
          
          <picture>
            <source type="image/webp" srcSet={AvailableOnMobileImg2Lazy}></source>
            <source type="image/png" srcSet={AvailableOnMobileImg2}></source>
            <img className="hover-img mobile" alt="Available OnMobile" src={AvailableOnMobileImg2} />
          </picture>
        </div>
      </div>
    </div>
  );
};

export default AvailableOnMobile;
