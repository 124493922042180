import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import TijoreeLogo from '../assets/img/tijoree.svg';
import TijoreeLogoMobile from '../assets/img/tijoree-mobile.svg';

import useAnalyticsEventTracker from './useAnalyticsEventTracker';
import { Button } from 'bootstrap';


const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const ScrollUp = () => {
    window.scrollTo(0, 0);
  }
  

  // Sticky Menu Area
  useEffect(() => {
    const isSticky = () => {
      const header = document.querySelector('.header-wrap');
      const scrollTop = window.scrollY;
      scrollTop >= 100 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };

    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  }, []);

  const handleCollapse = () => {
    const nav = document.getElementById('navbarSupportedContent');
    const btn = document.getElementById('navbarBtn');
    const html = document.documentElement;
    // nav.classList.remove('show');
    // btn.classList.add('collapsed');
    html.classList.remove('mobilenav');
    window.scrollTo(0, 0);
  };

  const gaEventTracker = useAnalyticsEventTracker('Contact us');

  const toggleHtmlClass = () => {
    const html = document.documentElement;
    if (html.classList.contains('mobilenav')) {
      html.classList.remove('mobilenav');
    } else {
      html.classList.add('mobilenav');
    }
  };
  return (
    <header className="white">
      <div className="container-md">
        <nav id="navbar" className="navbar navbar-expand-lg">
          <div className="left-menus d-flex align-items-center">
            <Link to="/" className="logo navbar-brand" onClick={(event) => {handleCollapse(event); ScrollUp();}}>
              <img className='desktop' src={TijoreeLogo} alt="tijoree" />
              <img className='mobile' src={TijoreeLogoMobile} alt="tijoree" />
            </Link>
            {/* <ul>
             <li>
             <Link
                      to="/products/current-account"
                      className="nav-link"
                      onClick={handleCollapse}
                    >
                      Current Account
                    </Link>
             </li>
             <li>
             <Link to="#" className="nav-link disabled" onClick={handleCollapse}>
                      Corporate Card
                    </Link>
             </li>
          </ul> */}
          </div>
          <div className='mobile-login'>
            <a
              onClick={() => gaEventTracker('Demo')}
              className=""
              href='https://app.tijoree.money'
              target='_blank'
            >
              Sign In
            </a>
          </div>
          <button className='menutoggle' onClick={toggleHtmlClass}>
            <svg className='open' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 24H28M4 15.9987H28M4 8C13.3726 8 18.6274 8 28 8" stroke="#4600A9" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
            <svg className='close' width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 24L24 8M8 8L24 24" stroke="#4600A9" strokeWidth="3" strokeLinecap="round" />
            </svg>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
              {/* <li className="nav-item">
                <Link
                  className={location.pathname === '/' ? 'nav-link active' : 'nav-link'}
                  to="/"
                  onClick={handleCollapse}
                >
                  Home
                </Link>
              </li> */}
              
              <li className="nav-item dropdown">
                <Link
                  to="#"
                  className={
                    location.pathname === '/products/current-account'
                      ? 'nav-link active'
                      : 'nav-link'
                  }
                >
                  <span>Products</span>
                </Link>
                <div className="dropdown-items">
                  <ul>   
                    <li className="nav-item item1">
                    <Link
                        to="/products/current-account"
                        className="nav-link"
                        onClick={handleCollapse}
                      >
                        <span className="arrow"></span>
                        Current Accounts
                      </Link>
                    </li>
                                       
                    <li className="nav-item item4">
                      <button className="nav-link corporate-card" onClick={() => {
                        handleCollapse(); navigate("/", {
                          state: { id: 'business-cc' },
                        });
                      }}>
                        <span className="arrow"></span>
                        Corporate Credit Cards
                      </button>
                    </li>
                    <li className="nav-item item3">
                    <Link to="/products/payment-collection" className="nav-link collection"  onClick={handleCollapse}>
                            <span className="arrow"></span>
                            Payment Collection Reminders
                          </Link>
                    </li>
                    <li className="nav-item item2">
                    <Link to="/products/pos" className="nav-link pos"  onClick={handleCollapse}>
                            <span className="arrow"></span>
                            POS (In-store Payments)
                          </Link>
                    </li>
                    
                  </ul>
                </div>
              </li>
              {/* <li className="nav-item">
                <Link
                  className={location.pathname === '/features' ? 'nav-link active' : 'nav-link'}
                  to="/features"
                  onClick={handleCollapse}
                >
                  Pricing
                </Link>
              </li> */}
              <li className="nav-item loan">
              <a
                  className="nav-link"
                  href='https://loan.tijoree.money/'
                  target='_blank'
                >
                  Apply for Loan
                </a>
              </li>

              <li className="nav-item mobile-show">
                <Link
                  className={location.pathname === '/faq' ? 'nav-link active' : 'nav-link'}
                  to="/faq"
                  onClick={handleCollapse}
                >
                  FAQs
                </Link>
              </li>
              
              <li className="nav-item">
                <Link
                  className={location.pathname === '/blog' ? 'nav-link active' : 'nav-link'}
                  to="/blog"
                  onClick={handleCollapse}
                >
                  Blog
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className={location.pathname === '/about' ? 'nav-link active' : 'nav-link'}
                  to="/about"
                  onClick={handleCollapse}
                >
                  About Us
                </Link>
              </li>
              <li className="nav-item login-item">
                <span className="divider-line"></span>
                <a
                  onClick={() => gaEventTracker('Demo')}
                  className="nav-link"
                  href='https://app.tijoree.money'
                  target='_blank'
                >
                  Sign In
                </a>
              </li>
            </ul>
            {/* <a onClick={() => gaEventTracker('Login')}
              className="btn-rounded login mobile"
              target='_blank'
              href='https://app.tijoree.money'
            >
              Login
            </a> */}
          </div>
          <div className='navaction'>
            <a
              onClick={() => gaEventTracker('Demo')}
              className="mobile-signin"
              href='https://app.tijoree.money'
              target='_blank'
            >
              Sign In
            </a>

            <Link
              onClick={() => {gaEventTracker('Demo'); handleCollapse()}}
              className="button btn-purple small request-demo"
              to="/form"
            >
              Get Started
            </Link>
          </div>

          {/* <a
            onClick={() => gaEventTracker('Demo')}
            className="btn-rounded yellow request-demo"
            href='https://app.tijoree.money'
            target='_blank'
          >
            Login
          </a> */}

          {/* <a onClick={() => gaEventTracker('Login')}
            className="btn-rounded yellow login desktop"
            target='_blank'
            href='https://app.tijoree.money'
          >
            Login
          </a> */}
        </nav>
      </div>
    </header>
  );
};

export default Header;
