import React, { useEffect } from 'react';
import Footer from '../../common/Footer';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
import { Helmet } from "react-helmet";
// Images imported from assets
import topBannerImg from '../../assets/img/about-top-img.png';
import yesbankUtility from '../../assets/img/yesbank-utility.png';
import { Link } from 'react-router-dom';

const About = () => {
  useEffect(() => {
    document.title = "About Us | Corporate Business Banking |Tijoree"
    let descMeta = document.querySelector('meta[name="description"]')
    descMeta.setAttribute("content", 'Discover Tijoree, your trusted partner for corporate business banking solutions. With a focus on delivering exceptional financial services, we empower businesses with cutting-edge digital banking tools and personalized support. Learn more about our comprehensive suite of services and how Tijoree can fuel your business growth. Contact us today and experience a new era of corporate banking excellence.');
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    // AOS.init({
    //   easing: 'ease-out-back',
    //   once: true,
    //   disable: 'mobile', 
    // });
  },
    []);

  return (
    <React.Fragment>
      <Helmet>
        <title> About Us | Corporate Business Banking |Tijoree
        </title>
        <meta name="description" content="Discover Tijoree, your trusted partner for corporate business banking solutions. With a focus on delivering exceptional financial services, we empower businesses with cutting-edge digital banking tools and personalized support. Learn more about our comprehensive suite of services and how Tijoree can fuel your business growth. Contact us today and experience a new era of corporate banking excellence." /></Helmet>
      <div className="page-wrapper">
        <section id="about-top-banner" className="about-top-banner grey-bg-2">
             <div className="container-md">
              <div className="about-img text-end">
              <img src={topBannerImg} alt="About Us" />
              </div>
                <div className="row">
                   <div className="col-lg-5">
                     <h1>About Us</h1>
                   </div>
                </div>
                <div className="row  inner-row justify-content-between">
                   <div className="col-lg-5">
                     <p className='size20'>Tijoree is a fintech neobank for businesses. We believe in the future of Indian commerce and want to supercharge India's growth story by providing helping companies seamlessly manage their finances and operations</p>
                   </div>
                   <div className="col-lg-6">
                     <p className='size20'>Our team is committed to building best-in-class B2B financial products by addressing 
                     the numerous pain points in the Indian financial ecosystem. We are a tight-knit team that rewards excellence 
                     and merit.<strong> To join us in our journey and be a part of our team, 
                      please send your resume to <a className="mail-link" href="mailto:talent@tijoree.money">talent@tijoree.money</a></strong></p> 
                   </div>
                </div>
             </div>
        </section>

        <section id="about-content" className="about-content">
             <div className="container-md">
                <div className="about-content-inner">
                {/* <div className="row pt-3">
                    <div className="col-xl-3 col-lg-3">
                       <h5>OUR GOALS</h5>
                    </div>
                    <div className="col-xl-8 col-lg-8">
                       <p className='size20'>We believe that Indian businesses, both small and large, are undeserved financially, and we aim to empower these businesses through powerful B2B financial products, including Current Accounts, Corporate Credit Cards, and Payment Collection Reminders & Working Capital Management tools.</p>
                    </div>
                </div> */}

                {/*
                <div className="row">
                    <div className="col-xl-3 col-lg-3">
                       <h5>OUR PARTNERS</h5>
                    </div>
                    <div className="col-xl-8 col-lg-8">
                       <p className='size20'>We have partnered with and are supported by respected institutions in the Indian BFSI ecosystem.</p>
                       <div className="partners">
                         <img src={yesbankUtility} alt="Yes bank and Utility" />
                       </div>
                    </div>
                </div>
              */}

                <div className="row addresses">
                    <div className="col-xl-3 col-lg-3">
                       <h5>OUR HEADQUARTERS</h5>
                    </div>
                    <div className="col-xl-5 col-lg-5">
                      <label className='size20'>MUMBAI</label>
                       <p className='size20'>Time Square Building, Andheri - Kurla Rd, Gamdevi, Marol, Andheri East, Mumbai, Maharashtra 400059</p>
                       <a href="https://maps.app.goo.gl/AJMhHuKDkdDcXeL96" target='_blank'>View on Map</a>
                    </div>
                </div>
                </div>
             </div>
        </section>
        <section id="cta-bg-curve" class="cta-bg-curve text-center">
           <div className="container-md">
             <h4>Join Tijoree & Grow your Business</h4>
             <Link className="button btn-purple" to={'/form'}>Get Started</Link>
           </div>
        </section>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default About;
