import React, { useEffect } from 'react';
import Footer from '../../common/Footer';
import { Link } from 'react-router-dom';
import termsConditionsPdf from '../../assets/img/privacy-policy.pdf';

const PrivacyPolicy = () => {
  useEffect(() => {
    document.title="Tijoree | Privacy Policy"
    // let descMeta=document.querySelector('meta[name="description"]')
    // descMeta.setAttribute("content", 'Experience the future of banking with Tijoree, the leading neobank offering digital banking services designed exclusively for SMEs and startups. Simplify your financial operations, streamline transactions, and unlock growth opportunities with our innovative solutions. Contact us today and embark on a seamless banking journey tailored to your business needs.');
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <div className="page-wrapper">
      <section id="text-content-page" className="text-content-page">
        <div className="container-md">
          <h1>Privacy Policy</h1>
          <p>This privacy policy ("<strong>Privacy Policy</strong>") informs You in detail how Your data is gathered, kept, and used by Us. Please take the time to thoroughly read this Privacy Policy. You provide Us permission to use & disclose your Personal Information (defined below) in accordance with this Privacy Policy by registering on the App (as defined below), via any medium: mobile app, website, web app, mobile site, or otherwise. Please refrain from using or gaining access to the App if You do not agree to the conditions of the policy.</p>
          <p>Without previous notice, our privacy policy may change at any time. Please frequently revisit this privacy statement to ensure
             that You are informed of any changes. The Tijoree website https://www.tijoree.money/ ("<strong>Website</strong>"), mobile site, web/desktop plugins, and mobile applications housed at https://www.tijoree.money/("<strong>App</strong>") shall be subject to this Privacy Policy uniformly. For the purposes of this Privacy Policy, all the terms and policies applicable to Website shall also be applicable to the App and vice-versa.</p>
          <p>Throughout this Privacy Policy, the capitalized terms "<strong>Tijoree</strong>" "<strong>We</strong>", "<strong>Us</strong>" and "<strong>Our</strong>" refers to Tijoree, its affiliates and assigns, and the terms
           “<strong>User</strong>”, “<strong>You</strong>”, “<strong>Your</strong>”, and “<strong>Yourself</strong>” refer to the users of the Website whose Personal Information is being collected in accordance with the terms of this Privacy Policy. In order to ensure Your safe use of the Website, Our methods and procedures regarding the collection and use of Personal Information are described below.</p>
         <p>The term "Personal Information" refers to data that Users give to Tijoree, such as their name, phone number, email address, and any other information connected to that data. It includes personal data that is classified as sensitive personal data or information under the Information Technology (Reasonable Security Policies and Procedures and Sensitive Personal Data or Information) Regulations, 2011.</p>
         <p>Sensitive personal data or information that is freely available, accessible in the public domain, or available under the Right to Information Act 2005, is excluded from the scope of the above stated definition.</p>
         <p>All physical and digital forms of Personal Information, whether they are gathered or maintained electronically or on paper, are covered by this Privacy Policy.</p>
         <p>Tijoree has the right to handle the data for and on behalf of the Users.</p>
         <p>If a User ever contributes data or other information about someone other than himself, herself or itself, the User guarantee that they have that person's permission to provide it for the stated purpose.</p>
         <p>Users agree to Our Privacy Policy by signing up for the Website. It is clarified that the Terms and Conditions that are provided separately, form an integral part of Your use of this Website and should be read in conjunction with this Privacy Policy. Your acceptance of the Terms and Conditions set out on the Website shall be considered as Your deemed acceptance of the terms of this Privacy Policy and vice versa.</p>
         <p>Please note that the browsing, access and use of the Website is restricted only for persons above the age of 18 years and persons who are “competent to contract within the meaning of the Indian Contract Act, 1872”. Our Website is not intended for minors and the Company does not target any minors. The Company does not knowingly collect Personal Information from minors. If You are under the age of 18 years, please do not register to browse, access or use the Website and do not provide us with any Personal Information. If You are a minor (i.e. under the age of 18 years), You may browse, access or use the Website only under the supervision and prior consent/ permission of a parent or a legal guardian.</p>
         <h4>Device Permissions</h4>
         <p>Your web-connected devices (referred to as "Devices") that are integrated with the Website allows Tijoree to gather information from and about them. The Users hereby grant the Website the following permissions by accessing and using the Website:</p>
         <h6>Device’s camera accessibility:</h6>
         <p>To improve your User experience, the App lets You scan photographs and documents using optical character recognition rather than having to manually enter the data or information needed to access the App's features. The App needs access to the Device's camera in order to make it easier for Tijoree to provide these services to the User. The User agrees that the App may access the Device camera in order to facilitate products and services.</p>
         <h6>Access to contacts from the Device:</h6>
         <p>To enhance your User experience, the App lets you instantly add contacts from Your device's phonebook rather than requiring you to manually enter these details every time. The App might need permission to access your Device's address book in order to make it easier for Tijoree to provide these services to the User. The User requires to provide permission for the Device's contact book to be accessed in order to facilitate the delivery of products and services through the App.</p>
         <h6>Storage of data on the Device:</h6>
         <p>Reports, pictures, invoice PDFs, and other items generated by the App can all be stored on Your device for offline access. The App may keep data locally on the User's Device to make it easier for Tijoree to provide these services to the User. The User agrees to the local storage of information on the User's Device for the purpose of providing products and services via the App.</p>
         <h6>Device location accessibility:</h6>
         <p>The App accesses the location of Your Device as a security precaution and a way to combat fraud in order to verify banking transactions processed through the App. The App may access the Device's location to make it easier for Tijoree to provide these services to the User. The User agrees that Tijoree may use the Device location to facilitate products and services through the App.</p>
         <h4>Collection of Information</h4>
         <p>Tijoree gathers and keeps the User's Personal Information that is occasionally submitted by the User when the User uses the Website/App. This enables Tijoree to offer services and features most likely to satisfy Your needs, as well as to accustom the Website to suit Your needs and make Your experience safer and simpler.</p>
         <p>Tijoree respects its Users' right to privacy and is committed to preserving it as described here. On a web page, "Cookies" or other comparable technology might be present. These are tools for gathering data to analyze our website's traffic and gauge the success of our marketing campaigns. Cookies are small files that Tijoree stores on Users’ computer's hard disc to help with service and interest-based information delivery. The majority of cookies are session cookies, which means that after a session ends, they are instantly removed from Users’ hard drive. If the Users’ browser allows, Users’ may always choose not to accept a cookie.</p>
         <p>Users are no longer anonymous to Tijoree once they register for an account or establish an account on the Website and give Tijoree their Personal Information. Tijoree attempts to make it clear which fields are necessary and which ones are optional. Users may not receive the product or service they have requested from Tijoree if they are unable to supply the information Tijoree wants.</p>
         <h6 className="gap-bottom">Information collected by Tijoree would include:</h6>
         <ul>
          <li>Tijoree collects and processes a variety of categories of Users' Personal Information in order to determine Users' eligibility and make the products and services available to them on the website, including the following:</li>
          <li>Personal information such as date of birth, age, marital status, birthplace, nationality, mother tongue; </li>
          <li>Contact information like name, address, telephone, and email address; </li>
          <li>Identifiers that are issued by the government, such as PAN and GST data; </li>
          <li>Financial data, such as bank account information, credit/debit card information, billing details, tax details, and payroll details (as applicable);</li>
          <li>Photographs and signature copies; </li>
          <li>Information which would help determine Users eligibility to avail the services offered on the Website; and</li>
          <li>Any other information that Tijoree is required to collect as per specific mandate from any bank, or under applicable law, regulation, rule or statute in India.</li>
         </ul>
         <p>In addition to the aforementioned, Tijoree may gather any additional consent or information for the mobile application in order to deliver a seamless mobile experience, such as but not limited to: SMS access for automatic OTP verification, camera/gallery/contacts access, and any other permissions necessary to enable specific functionalities within the mobile application.</p>
         <h6 className="gap-bottom">Sharing of Information and third parties disclosures</h6>
         <p>As a technology aggregator, Tijoree provides services through the Website/App. In light of this, Tijoree may be forced to disclose User information in order to grant Users access to its services, to meet its legal duties, and to prevent, detect, mitigate, and look into fraudulent or unlawful acts relating to its services. Tijoree does not share Your Personal Information with third parties unless expressly permitted under the terms contained herein.</p>
         <p>If obliged to do so by law or in the good faith belief that such disclosure is advisable in order to answer to subpoenas, court orders, or other legal process, Tijoree may disclose Personal Information.</p>
         <p>In the good faith belief that such disclosure is reasonably necessary to: (a) enforce the Terms and Conditions or this Privacy Policy; (b) respond to claims that an advertisement, posting, or other content violates the rights of a third party; or (c) protect the rights, property, or personal safety of Users or the public, Tijoree may disclose Personal Information to law enforcement offices, third party rights owners, or others.</p>
         <p>To third parties who perform services for Us, We may divulge Personal Information as long as they abide by the confidentiality responsibilities outlined in this Privacy Policy. These third parties are only permitted to use Users' Personal Information as required to deliver the website services that Users have requested or accessed. These services could include offering cloud computing infrastructure, reporting website crashes, analyzing website usage patterns to better serve Users, and offering customer care or account management.</p>
         <h4>Data Retention</h4>
         <p>As long as the User's account is active or as long as it's necessary to provide Website services, Tijoree will keep track of the Users' information. In order to fulfil its legal duties, settle disputes, or pursue other business objectives, Tijoree must keep and use the data it has gathered. Tijoree is not obligated to keep the Users' information if the Users deactivate, cancel, or unsubscribe from their account with Tijoree. In order to maintain compliance with applicable regulations, Tijoree may keep Users' information for a period of twelve months after they cancel, deactivate, or unsubscribe from their Tijoree accounts.</p>
         <h4>Data Security</h4>
         <p>The Users are aware of all security concerns, including the potential for third parties to intercept any online transactions or account statements they make or for them to learn the details of their transactions or statements. Users acknowledge and agree that they will not in any way hold Tijoree responsible for this. It is Your responsibility and risk to use and keep any information, including but not limited to the credit card number, PIN, password, account information, transaction history, account balances, and any other information accessible or kept on the User's Device. Furthermore, Users recognise that not all content or communications from or to their website may be encrypted by Tijoree.</p>
         <h4>Collection of sensitive personal data or information by Tijoree</h4>
         <p>In accordance with the rules of this Privacy Policy, Users have the right to withdraw their consent at any time in writing by sending an email to Tijoree at grievances@tijoree.money. Please be aware, though, that withdrawal of consent will only be effective going forward rather than being retrospective.</p>
         <p>To access, examine, change, or correct their sensitive personal data or information, or to withdraw their consent to provide such data or information, You may write to Tijoree at grievances@tijoree.money. The veracity of the information submitted by Users is not guaranteed by Tijoree.</p>
         <p>The Users understand and agree that Tijoree may limit or deny their ability to access, alter, or withdraw their consent to provide sensitive personal data or information as may be necessary to comply with any applicable laws, law enforcement requests, or legal processes.</p>
         <h4>Links to other sites</h4>
         <p>On the Website, there may be links to third-party advertisements, third-party websites, or other third party electronic communication service run by a third party. Unless otherwise specifically stated on the Website, such links may not be managed by, related with, or associated with Tijoree.</p>
         <p>Tijoree disclaims any liability for any transmissions of any kind that Users may receive from third-party websites. Tijoree does not control or vouch for the truth, integrity, or caliber of any information, data, text, software, music, sound, photographs, graphics, videos, messages, or other materials found on such third-party websites, nor does it make any representations regarding the privacy practices or policies of such third parties or the terms of use of such third-party websites. The inclusion or omission of a third party website, its operator, or any of its content does not constitute sponsorship by Tijoree of those entities. It is advised that Users study the privacy policies of such third party websites prior to accessing such websites as the information given by Users to such third party websites shall be managed in line with such third party websites' privacy policies.</p>
         <h4>Acknowledgements</h4>
         <p>By the usage of Website/ App by the Users, the Users acknowledge that Tijoree will not be obligated to return their application or copies of any documents they have submitted to them upon their request. This applies only to the extent authorized by applicable legislation. It is against the law and a breach of these terms to gain unauthorized access to the Website. The Users acknowledge that Tijoree's offered interface is the only way to access the website.</p>
         <h6 className="gap-bottom">The Users acknowledge and agree that this Privacy Policy:</h6>
         <ul>
           <li>Is clear, accessible, and provides statements of the Tijoree's information security policies and practices; </li>
           <li>Provides for disclosure of information; </li>
           <li>Provides for the various types of personal or sensitive personal data of information to be collected; </li>
           <li>Provides for the purposes of collection and usage of the information; and </li>
           <li>Provides for reasonable security practices and procedures. </li>
           <li>If the Users have any questions in respect of this Privacy Policy, You may write to the Grievance Officer appointed as per the provisions of the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011:</li>
         </ul>
         <div className="grevience">
           <h6>Grievance & Redressal Officer:</h6>
           <div>Name: Raghav Chaturvedi </div>
           <div>Designation: Director </div>
           <div>Email: grievances@tijoree.money </div>
           <div>Tijoree Invest Private Limited</div>
         </div>
         <div className="content-footer">
            <div>*Last Updated: 14/03/2023</div>
            <div>*Please note that this is not exhaustive, and an exhaustive version will be updated on 23/03/2023</div>
         </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
